.ProjectLandingPageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ProjectLandingPageHeader {
        width: 100%;
        height: 60vh;
        @include mQ(350px) {
            height: calc(100vh - 65px); }
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        .title {
            font-weight: bold;
            max-width: 800px;
            text-align: center; }
        .opportunities {
            padding: 10px 30px;
            border-radius: 30px;
            background: #BF1700;
            cursor: pointer;
            margin-top: 50px;
            &:hover {
                background: #E9523E; }
            a {
                color: inherit;
                text-decoration: none; } }
        .logo {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 500px;
            @include mQ(800px) {
                width: 70%; } } }
    .infoContainer {
        width: 75%;
        display: flex;
        flex-direction: column;
        margin: 30px 0 100px 0;
        align-items: center;
        @include mQ(1100px) {
            width: 90%; }
        .mainInfoContainer {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;

            .iconsPairsContainer {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @include mQ(900px) {
                    width: 100%;
                    justify-content: space-between; }
                span {
                    display: flex;
                    align-items: center; }
                .iconPair {
                    display: flex;
                    align-items: center;
                    margin-right: 25px;


                    h6 {
                        @include mQ(600px) {
                            font-size: 15px; } }
                    svg {
                        font-size: 25px;
                        margin-right: 5px;
                        @include mQ(600px) {
                            font-size: 20px; } } }
                .clickableIcon {
                    margin-right: 10px;
                    cursor: pointer;
                    width: 25px;
                    font-size: 25px; }

                a {
                    color: inherit;
                    display: flex;
                    align-items: center;
                    .document {
                        color: #E9523E; }
                    .website {
                        color: #21BCE9; } }
                .highlighted {
                    color: #FFDA83;
                    display: flex;
                    align-items: center; } }



            .stageContainer {
                padding: 10px 40px;
                border-radius: 30px;
                @include mQ(900px) {
                    margin-top: 20px;
                    width: 100%; } } }
        .summaryContainer {
            width: 100%;
            margin: 30px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include mQ(900px) {
                width: 90%; } }
        .updatesOpportunitiesContainer {
            width: 100%; }

        .outputsContainer {
            margin-top: 50px; }
        .infoCard {
            padding: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            .updateTitle {
                margin-bottom: 30px; }
            .updateDate {
                color: #21BCE9; }
            .outputDate {
                color: #E9523E; } }
        .timelineContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            @include mQ(800px) {
                width: 100%; }
            .timeline {
                width: 70%;
                .MuiTimelineContent-root {
                    max-width: 700px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    overflow-x: hidden; }
                @include mQ(1500px) {
                    width: 90%; }
                @include mQ(750px) {
                    width: 100%; }
                .separator {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .timelineConnector {
                        width: 2px;
                        height: calc(50% - 24px);
                        background: #bdbdbd; } } }


            .updateContentContainer {
                padding: 6px 16px;
                transition: all 100ms ease;
                p {
                    width: inherit; }
                form {
                    display: flex;
                    flex-direction: column;
                    .buttonContainer {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 5px; } } } }

        .noUpdatesContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .noUpdates {
                font-size: 280px;
                color: #ececec; } } } }
