@import '../../mixins';

.projectUpdateContainer {
    width: 100%;
    // min-height: calc(100vh - 65px)
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    .title {
        margin: 40px  0;
        font-size: 35px;
        width: 30%;
        color: #611F69;
        text-align: center;
        @include mQ(1150px) {
            width: 60%; }
        @include mQ(600px) {
            width: 90%; }
        @include mQ(400px) {
            font-size: 30px; } }
    .tokenContainer {
        display: flex;
        width: 400px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 40px 50px 40px;
        margin: 50px 0;

        .iconContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1005%;
            width: 100px;
            height: 100px;
            background: #E9523E;
            color: white;
            transform: translateY(-50%);
            svg {
                font-size: 50px; } }
        .tokenInput {
            margin: 0; }
        .submitToken {
            padding: 10px 30px;
            background: #E9523E;
            color: white;
            border: none;
            width: 100%;
            cursor: pointer;
            transition: all 100ms ease;
            margin-top: 40px;
            &:hover {
                background: #B81600; } } } }
.formContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 30px 0;
    @include mQ(1300px) {
        width: 60%; }
    @include mQ(800px) {
        width: 80%; }

    @include mQ(600px) {
        width: 90%; }
    form {
        width: 80%;
        padding: 0;
        display: flex;
        flex-wrap: wrap; } }
.errorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 70px;
    .MuiAlert-standardError {
        width: 30%;
        min-width: 400px; } }

.projectSuccessMsgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .success {
        color: #1ABC9C;
        font-size: 150px; }
    .msg {
        margin-bottom: 20px; } }
