@import "./Pages/ProjectRegister/registrationForm";
@import "./Pages/ProjectUpdate/projectUpdate";
@import "./Pages/ProjectsList/landingPage";
@import "./Pages/ProjectsList/projectLandingPage";
@import "./Pages/UserProfile/userProfile";
@import "./Pages/UserRegistration/userRegistration";
@import "./Pages/EditUserProfile/editUserProfile";

.navContainer {
    position: relative;
    z-index: 2000;
    .logoImg {
        width: 30px;
        height: 30px;
        margin: 0 8px 0 80px;
        @include mQ(750px) {
            // margin-left: 20px !important
            display: none; } }
    .mobileNavMenu {
        margin-right: 10px;
        cursor: pointer;
        display: none;
        @include mQ(750px) {
            display: inherit; } }
    .navButtonContainer {
        display: flex;
        @include mQ(750px) {
            display: none; } }
    .mobileNavigationContainer {
        position: absolute;
        z-index: 1000;
        top: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: white;
        transition: all 300ms ease;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        a {
            padding: 10px 0; } } }

.loadingContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 500px;
    justify-content: center;
    .MuiCircularProgress-root {
        color: #61CE70;
        width: 80px  !important;
        height: 80px !important; }

    span {
        margin-top: 30px;
        font-size: 20px; } }


a {
    color: inherit;
    text-decoration: none; }
.Planning {
    background: #CFDFFF; }
.Design {
    background: #D0F0FD; }
.Prototype {
    background: #C2F5E9; }
.Testing {
    background: #D1F7C4; }
.Documenting {
    background: #FFDA83; }
.Launch {
    background: #FEE2D5; }
.MuiInputLabel-asterisk, .MuiFormLabel-asterisk {
    color: #E9523E; }
.error {
    background: #E9523E; }

.hide {
    opacity: 0;
    visibility: hidden;
    z-index: -1 !important; }
.projectsListContainer, .projectsLoadingContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 70px;
    min-height: 500px;
    @include mQ(900px) {
        justify-content: center; }
    .cardContainer {
        width: 320px;
        height: 440px;
        margin-bottom: 40px;
        @include mQ(900px) {
            margin: 0 35px 40px 35px; }

        .cardHeader {
            width: 100%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            .highlightedContainer {
                position: absolute;
                top: 20px;
                right: 10px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFDA83;
                background: white;
                border-radius: 100%; }
            .cardTitle, .cardCreatedDate {
                width: 80%;
                margin-bottom: 5px; }
            .cardTitle {
                font-size: 14px;
                font-weight: bold;
                margin-top: 20px; }
            .cardCreatedDate {
                font-size: 12px;
                display: flex;
                align-items: center;
                svg {
                    font-size: 15px;
                    margin-right: 5px; } }
            .cardStage {
                font-weight: bold;
                position: absolute;
                right: 20px;
                bottom: 10px; } }


        .cardImg {
            display: flex;
            width: 100%;
            height: 320px;
            // background-size: cover
            // backgorund-position-y: center
            justify-content: center;
            position: relative;
            overflow-x: hidden;
            p {
                width: 80%;
                margin-top: 20px; }

            .moreInfoContainer {
                background: #00ACDE;
                color: white;
                font-weight: bold;
                padding: 10px 20px;
                border-radius: 30px;
                cursor: pointer;
                position: absolute;
                bottom: 20px;
                right: 15px;
                &:hover {
                    background: #2980b9; }

                a {
                    color: inherit;
                    text-decoration: none; } } } } }



.accountContainer {
    width: 50px;
    margin-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include mQ(900px) {
        margin-right: 10px; }
    @include mQ(500px) {
        margin-right: 0; }
    .accountDetailtsMobile {
        display: none;
        @include mQ(750px) {
            display: block; } }
    .MuiAvatar-root {
        cursor: pointer;
        transition: all 100ms ease;
        @include mQ(750px) {
            display: none; } } }

.accountDetailtsContainer {
    ul, li {
        padding: 0;
        width: 180px; }
    .accountDetails {
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .MuiAvatar-root {
            width: 60px;
            height: 60px; }
        .slackHandle {
            margin-top: 10px;
            color: #2E2E2E;
            font-weight: bold; } }

    .accountOptionBtn {
        width: 100%;
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px; }
    .navItem {
        background: #F0F8FE;
        display: none;
        @include mQ(750px) {
            display: flex; } }
    .editProfile {
        background: #D0F0FD;
        color: #21BCE9;
        &:hover {
            background: #21BCE9;
            color: white; } }
    .logout {
        background: #FEE2D5;
        color: #E9523E;
        &:hover {
            background: #E9523E;
            color: white; }
        .hideAccountDetails {
            opacity: 0;
            visibility: hidden; } } }

.supportContainer {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include mQ(500px) {
        right: 10px;
        bottom: 10px; }
    .supportIcon {
        margin-top: 5px;
        svg {
            font-size: 30px; } } }
.supportFormContainer {
    width: 400px;
    transition: all 200ms ease;
    margin-right: 40px;
    position: fixed;
    bottom: 120px;
    right: 50px;
    padding: 20px;

    @include mQ(500px) {
        margin: 0;
        min-width: 0;
        width: 310px;
        bottom: 80px; }
    form {
        display: flex;
        flex-direction: column;
        padding: 20px 10px;
        min-height: 450px;
        justify-content: space-between;
        @include mQ(500px) {
            // padding: 10px 5px
            width: 90%; }
        .MuiButton-root {
            margin-top: 20px; }
        strong {
            cursor: pointer;
            color: #61CE70; } } }
.supportLoadingContainer {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    background: #2B3852;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    min-width: 280px;
    justify-content: space-between;
    z-index: 2000;
    @include mQ(600px) {
        bottom: 80px; } }

.snavkbarContainer {
    @include mQ(600px) {
        margin-bottom: 80px; } }
.goUpIcon {
    position: fixed;
    bottom: 60px;
    right: 25px;
    @include mQ(500px) {
        right: 5px;
        bottom: 40px; } }
.loginPopover {
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    align-items: center;
    form {
        width: 100%;
        display: flex;
        flex-direction: column; }
    .menuElement {
        margin: 10px 0; }
    button {
        img {
            width: 20px;
            margin-right: 10px; } } }

.blueLayer {
    width: 100%;
    height: 100%;
    background: #2B3852;
    opacity: 0.6; }
