.userRegistrationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .userRegistrationFormContainer {
        width: 90%;
        height: 85vh;
        max-width: 1000px;
        max-height: 600px;
        position: relative;
        display: flex;
        margin-bottom: 50px;
        .container {
            min-width: 330px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .tabOptionsContainer {
                width: 100%;
                height: 80%;
                overflow-y: auto;
                padding: 20px 0;
                transition: all 300ms ease;
                color: rgba(0, 0, 0, 0);
                text-shadow: 0 0 black;
                &::-webkit-scrollbar {
                    background-clip: padding-box;
                    border: 5px solid transparent; }
                &::-webkit-scrollbar-track {
                    display: none; }
                &::-webkit-scrollbar-thumb {
                    background-clip: padding-box;
                    border: 5px solid transparent;
                    box-shadow: inset 0 0 0 5px; }
                &:hover {
                    color: rgba(97, 202, 112, 1); }

                form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-right: 10px;
                    color: black !important;
                    .titleContainer {
                        width: 100%;
                        margin: 10px 0 20px 0;
                        border-bottom: 1px solid #61CE70; }

                    .formElement {
                        margin-bottom: 20px; }
                    button {
                        margin-bottom: 10px;

                        img {
                            width: 20px;
                            margin-right: 10px; } } } }
            .tabNavContainer {
                width: 100%;
                display: flex;
                justify-content: space-between; }
            .tabsContainer {
                width: 100%;
                display: flex;
                border-top: 1px solid #61CE70;
                padding: 10px 0;
                justify-content: space-between;
                .tab {
                    display: flex;
                    flex-direction: column;
                    width: 30%;
                    align-items: center;
                    cursor: pointer;
                    border-bottom: 2px solid white;
                    transition: all 300ms ease;
                    padding: 10px 0;
                    &:hover {
                        color: #61CE70;
                        border-bottom: 2px solid #61CE70; }
                    svg {
                        margin-bottom: 5px; } }
                .activeTab {
                    color: #61CE70;
                    border-bottom: 2px solid #61CE70; } }
            .textContainer {
                display: flex;
                flex-direction: column;
                z-index: 5;
                padding: 30px 70px;
                color: white;
                position: absolute;
                align-items: center;
                width: 100%;
                height: 100%;
                text-align: center;
                justify-content: space-between;
                img {
                    width: 300px; } } } } }
