.editUserContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    .textFieldContainer {
        width: 50%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin: 20px 0 50px 0;
        .formElement {
            margin: 10px 0; } } }
