.headerContainer {
    width: 100%;
    height: 60vh; }

.userProfileContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    .userInfoContainer {
        width: 70%;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: -20vh;
        flex-direction: column;
        padding: 0 50px 30px 50px;
        max-width: 700xp;
        .socialInfoContainer {
            position: absolute;
            left: 0;
            top: 50px;
            transform: translateX(-50%);
            .socialIconContainer {
                width: 50px;
                height: 50px;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                margin-bottom: 10px;
                cursor: pointer; } }
        .avatarContainer {
            width: 170px;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            border-radius: 100%;
            margin-top: -85px;
            position: relative;
            .editIconContainer {
                background: #FAC710;
                border: 5px solid white;
                bottom: 5px;
                right: 15px;
                width: 50px;
                height: 50px;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                svg {
                    font-size 25px {} } } }
        .skillsContainer {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .skill {
                background: #61CE70;
                padding: 20px;
                margin: 5px 20px 5px 0; } }
        .otherSkillsContainer {
            width: 100%;
            padding: 20px;
            background: #D1F7C4;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0; }
        .projectContainer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            .elementsContainer {
                display: flex;
                flex-direction: column;
                .iconContainer {
                    position: relative;
                    width: 70px;
                    height: 70px;
                    background: #61CE70;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 30px;
                    .smallIcon {
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: -10px;
                        svg {
                            font-size: 16px; } } }
                .membersContainer {
                    display: flex;
                    margin-bottom: 10px;
                    a {
                        // transition: all 300ms ease

                        &:hover {
                            z-index: 20 !important; } }
                    .memberIconContainer {
                        width: 50px;
                        height: 50px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        transition: all 300ms ease;
                        justify-content: center;
                        cursor: pointer;
                        &:hover {
                            z-index: 20 !important; } } } } } } }
.membersFullListContainer {
    display: flex;
    cursor: pointer;
    transition: all 200ms ease;
    padding: 10px 20px;
    min-width: 300px;
    &:hover {
        background: #E9F9FF; } }
