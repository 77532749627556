
.landingPageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .loginContainer {
        width: 100%;
        height: calc(100vh - 50px);
        background: #2B3852;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 5000; }
    header {
        width: 100%;
        display: flex;
        height: calc(100vh - 50px);


        .statsContainer {
            width: 20%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            @include mQ(1150px) {
                display: none; }
            .statsCard {
                width: 90%;
                height: 90%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 30%;
                z-index: 2;
                min-width: 330px;

                .statsLoadingContainer, .statsWrapper {
                    padding:  30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 100%;
                    .statLoadingItemContainer {
                        display: flex;
                        margin: 10px 20px;
                        width: 195px;
                        justify-content: space-between; }
                    .statsTitle {
                        color: #2B3852;
                        font-size: 25px; }
                    .statItemContainer {
                        display: flex;
                        align-items: center;
                        margin-left: 30px;
                        font-size: 15px;
                        svg {
                            margin-right: 5px; } }

                    .statPercentageContainer {
                        display: flex;
                        justify-content: center;
                        margin-left: 30px;
                        font-size: 15px;
                        flex-direction: column;
                        .stateContainer {
                            position: relative;
                            width: 100%;
                            height: 25px;
                            background: #EEEEEE;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end; }
                        .percentage {
                            margin-right: 15px;
                            z-index: 5;
                            font-size: 12px; }
                        .statePercentage {
                            position: absolute;
                            height: 100%;
                            left: 0; } } } } }

        .joinUsContainer {
            width: 80%;
            background: url("https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260");
            background-size: cover;
            background-position-y: bottom;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            @include mQ(1150px) {
                width: 100%; }
            .helpfullLogo {
                width: 300px;
                height: auto;
                margin-bottom: 80px;
                @include mQ(600px) {
                    width: 250px; } }
            .callForActionContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                a {
                    margin-bottom: 20px; } }

            // .callForAction
            //     width: 300px
            //     height: 60px
            //     display: flex
            //     align-items: center
            //     justify-content: center
            //     color: white
            //     margin: 10px 0
            // .projectResgiterButton
            //     background: #61CE70
            //     &:hover
            //         background: #1ABC9C
            // .opportunitiesButton
            //     background: #2B3852
            //     &:hover
            //         background: #405470

            .signUpButtonContainer {
                display: flex;
                align-items: center;
                width: 300px;
                cursor: pointer;
                @include mQ(600px) {
                    width: 200px; }

                &:hover .iconContainer {
                    background: #61CE70; }
                .iconContainer {
                    width: 70px;
                    height: 70px;
                    background: #2B3852;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include mQ(600px) {
                        width: 50px;
                        height: 50px; }

                    img {
                        width: 30px;
                        height: auto; } }
                .signUp {
                    width: calc(100% - 70px);
                    text-align: center;
                    font-size: 20px;
                    @include mQ(600px) {
                        font-size: 15px; } } }
            .motivation {
                width: 600px;
                font-size: 30px;
                text-align: center;
                margin-bottom: 60px;
                @include mQ(600px) {
                    width: 90%;
                    font-size: 20px; } } } }

    .singnInContainer {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #2B3852;
        @include mQ(450px) {
            height: 40vh; }
        .text {
            max-width: 980px;
            text-align: center;
            color: white;
            line-height: 70px;
            font-weight: 300;
            margin-bottom: 30px;
            @include mQ(1100px) {
                font-size: 30px;
                max-width: 600px;
                line-height: 50px; }
            @include mQ(650px) {
                font-size: 25px;
                max-width: 500px;

                line-height: 30px; }
            @include mQ(450px) {
                width: 100%;
                padding: 0 10px;
                font-size: 18px; }
            button {
                text-transform: none; } }
        .signUpText {
            position: absolute;
            bottom: 30px;
            color: #F0F8FE;
            width: 100%;
            display: flex;
            justify-content: center;
            @include mQ(1100px) {
                font-size: 13px; }



            a {
                color: #E9523E;
                margin-left: 5px; } } }
    .projectsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 120px;
        @include mQ(900px) {
            width: 100%;
            margin: 0;
            align-items: center;
            padding: 0 50px; }
        @include mQ(900px) {
            padding: 0 20px; }
        .noResultContainer {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                filter: opacity(10%);
                @include mQ(600px) {
                    width: 80%; } }
            .noResultMeassageContainer {
                width: 20%;
                position: absolute;
                z-index: 5;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                @include mQ(1500px) {
                    width: 40%; }
                @include mQ(600px) {
                    width: 80%; }
                .MuiTypography-h1 {
                    margin: 0;
                    margin-bottom: 20px; } } }

        h1 {
            font-size: 50px;
            color: #2E2E2E;
            margin: 80px 0 0 0;
            @include mQ(1100px) {
                font-size: 50px; }
            @include mQ(900px) {
                text-align: center;
                margin-bottom: 20px; } }
        .projectsMission {
            width: 50%;
            color: #626262;
            font-size: 15px;
            @include mQ(1100px) {
                font-size: 15px;
                width: 90%; }
            @include mQ(900px) {
                text-align: center;
                width: 90%; } }
        .searchContainer {
            width: 100%;
            margin-top: 40px;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: space-between;
            form {
                display: flex;
                align-items: center;
                @include mQ(900px) {
                    width: 100%;
                    padding: 0 20px;
                    justify-content: center; } }
            .MuiFormControl-root {
                width: 100%;
                .MuiInputLabel-shrink {
                    opacity: 0;
                    visibility: hidden; }
                label {
                    z-index: 5;
                    top: 50%;
                    left: 50px;
                    transform: translateY(-50%);
                    transition: all 300ms ease;
                    @include mQ(400px) {
                        left: 20px;
                        font-size: 12px; } }
                .MuiInputBase-root {
                    margin: 0; }

                input {
                    background: #E2E2E2;
                    padding: 10px 0 10px 40px;
                    border-radius: 30px;
                    min-width: 350px;
                    @include mQ(900px) {
                        width: 100%; }
                    @include mQ(700px) {
                        min-width: auto; }
                    @include mQ(400px) {
                        padding: 5px 0 5px 20px; } }

                .MuiInput-underline {
                    &:before {
                        content: "";
                        display: none; }
                    &:after {
                        content: "";
                        display: none; } } }
            .sortIcon {
                font-size: 30px;
                cursor: pointer;
                margin-left: 5px; } }




        .filterContainer {
            width: 100%;
            display: flex;
            margin: 10px 0 50px 0 {
                justify-content: center; }
            .activatedFilterContainer {
                display: flex;
                justify-content: flex-start;
                width: 200px;
                min-height: 40px;
                @include mQ(900px) {
                    width: 100%;
                    padding: 0 20px;
                    min-height: 30px; }

                .activatedFilter {
                    width: 40px;
                    height: 40px;
                    color: white;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-right: 10px;
                    @include mQ(900px) {
                        width: 30px;
                        height: 30px;
                        svg {
                            font-size: 17px; } } } } } } }

.sortContainer {
    display: flex;
    position: relative;
    .MuiSelect-root {
        font-size: 18px; } }


.filterOptionsContainer {
    width: 250px;
    position: absolute;
    flex-direction: column;
    top: 30px;
    left: 420px;
    z-index: 10000;
    transition: all 200ms ease !important;

    @include mQ(1150px) {
        left: 150px; }
    @include mQ(900px) {
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        height: 50px;
        top: calc(100% - 50px);
        width: 50%;
        min-width: 280px;
        display: flex;
        justify-content: space-around;
        flex-direction: row; }
    .filterOption {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 10px;
        position: relative;
        @include mQ(900px) {
            justify-content: center; }

        .showOptionsContainer {
            @include mQ(900px) {
                // transform: rotate(90deg)
                display: none; } }

        &:hover {
            background: #F5F5F5; }
        &:hover .optionsContainer {
            opacity: 1;
            visibility: visible; }
        &:hover  .optionsCalendarContainer {
            opacity: 1;
            visibility: visible; }
        .removeFilterIcon {
            font-size: 20px;
            @include mQ(900px) {
                display: none; } }
        .labelContainer {
            display: flex;
            align-items: center;
            margin-left: 20px;
            color: #4D4F5C;
            svg {
                margin-right: 5px; }
            .labelText {
                @include mQ(900px) {
                    display: none; } } }
        .MuiButtonBase-root {
            transform: translateX(9px); }
        .Mui-checked {
            color: #FFDA83; }
        .optionsContainer {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 260px;
            top: 0;
            left: 100%;
            z-index: 5;
            opacity: 0;
            visibility: hidden;
            transition: all 200ms ease !important;
            @include mQ(900px) {
                left: 0;
                min-width: 220px;
                transform: translateY(-100%); }
            .stageOption, .categoryOption {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                &:hover {
                    background: #F5F5F5; }
                .iconContainer {
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                    margin: 0 10px 0 20px; } }
            .teamSizeOption {
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;

                .sizeLabel {
                    color: #4D4F5C;
                    margin: 0 15px; }
                .MuiSlider-root {
                    color: #BF1700; } } }
        .optionsCalendarContainer {
            display: flex;
            background: white;
            position: absolute;
            top: 0px;
            left: 100%;
            z-index: 5;
            opacity: 0;
            visibility: hidden;
            @include mQ(900px) {
                left: 0;
                transform: translate(-50%,-100%); }

            .react-calendar {
                width: 320px;
                padding: 0 10px;
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                background: white;

                .calendarDay {
                    background: white;
                    border: none;
                    padding: 15px 0;
                    &:hover {
                        color: #1ABC9C;
                        cursor: pointer;
                        background: #1abc9c3b;
                        border-radius: 100%; }
                    &:focus {
                        outline: none; } }
                .react-calendar__tile--active {
                    color: #1ABC9C;
                    cursor: pointer;
                    background: #1abc9c3b;
                    border-radius: 100%; }
                .react-calendar__navigation {
                    height: 50px;
                    background: white;
                    padding: 5px;
                    button {
                        border: none;
                        background: white;
                        font-size: 20px;
                        &:focus {
                            outline: none; } } }
                .react-calendar__month-view__weekdays {
                    height: 30px;
                    background: white;
                    text-align: center;
                    font-weight: bold;
                    border-bottom: 1px solid #E9E9F0;
                    text-transform: uppercase; } } } } }
