@import '../../mixins';
body {
    scroll-behavior: smooth; }
.registrationContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0 60px 0;
    .title {
        margin: 20px 0;
        font-size: 35px;
        width: 30%;
        color: #2b3852;
        text-align: center;
        @include mQ(1150px) {
            width: 60%; }
        @include mQ(600px) {
            width: 90%; }
        @include mQ(400px) {
            font-size: 30px; } }
    .formContainer {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;


        @include mQ(600px) {
            width: 90%; }
        .formHeaderContainer {
            width: 80%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;

            .stageSelect {
                width: 50%;
                padding: 5px 20px;
                border-radius: 30px;
                max-height: 40px;
                margin-top: 10px;
                min-width: 250px;
                @include mQ(400px) {
                    min-width: 200px; }

                .MuiSelect-select {
                    @include mQ(400px) {
                        font-size: 12px !important; } } } }


        form {
            width: 80%;
            margin: 0;
            .Mui-checked .MuiIconButton-label {
                color: #61CE70; }
            .agreementContainer {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 30px; }
            .selectContainer {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .MuiSelect-select {
                background: #f5f5f5; }
            .listContainer {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                .listItem {
                    margin: 10px 5px 0 0; }

                .deleteItem {
                    font-size: 12px;
                    cursor: pointer;
                    margin-left: 30px; } }
            .MuiFormControl-root {
                .MuiFormHelperText-root {
                    display: flex; }
                .help {
                    min-width: 20px;
                    min-height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                    background: #61CE70;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    margin-left: 5px;
                    cursor: pointer; }

                .MuiTooltip-tooltip {
                    background: #2E2E2E;
                    opacity: 1; }
                .MuiInput-multiline {
                    padding: 0; }
                label {
                    z-index: 200; }

                input {
                    background: #F5F5F5; }
                input:focus {
                   background: #C2FFCB; }
                textarea {
                    background: #F5F5F5;
                    padding: 6px 0 7px 0; }
                textarea:focus {
                   background: #C2FFCB; }
                .Mui-focused::after {
                    border-color: #61CE70; }
                .addItem {
                    font-size: 30px;
                    cursor: pointer;
                    color: #405470;
                    transition: all 100ms ease;
                    &:hover {
                        transform: translateY(-3px);
                        color: #2B3852; } } }

            .submitContainer {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;
                .submit {
                    background: #61CE70;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    border: none;
                    padding: 15px 50px;
                    transition: all 300ms ease;

                    &:hover {
                        background: #AFD36C; } } } } } }

.validationContainer {
    width: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading {
        color: #61CE70; }
    .projectMsgContainer {
        width: 100%;
        // height: calc(100vh - 60px)
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin: -20px 0 -60px 0;
        .successContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #2B3852;
            svg {
               color: #61CE70;
               font-size: 250px; }
            .successMessage {
                width: 80%;
                text-align: center;
                font-size: 20px; }
            img {
                width: 300px;
                height: auto; } }
        .projectCardContainer {
            width: 320px;
            display: flex;
            flex-direction: column;
            margin: 50px 0 20px 0;
            .projectHeader {
                width: 100%;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 15px;
                position: relative;
                .projectTitle {
                    font-size: 25px;
                    font-weight: bold; }
                .projectDate {
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    svg {
                        font-size: 15px;
                        margin-right: 5px; } }
                .stageContainer {
                    position: absolute;
                    bottom: 10px;
                    right: 40px;
                    font-weight: bold; } }
            .projectBody {
                background: url("https://res.cloudinary.com/dffifnsyc/image/upload/v1588771663/images/no_image.png");
                background-size: cover;
                width: 100%;
                height: 320px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                span {
                    background: #00ACDE;
                    color: white;
                    padding: 10px 20px;
                    border-radius: 30px;
                    cursor: pointer;
                    margin: 20px 15px;
                    &:hover {
                        background: #2980b9; } } } } } }









.notSignInContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    .notSignInCard {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 30px 30px 30px;
        .iconContainer {
            transform: translateY(-50%);
            font-size: 100px;
            background: #61CE70;
            border-radius: 100%;
            color: white;
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                font-size: 70px; } }
        .titleSignIn {
            margin-bottom: 30px;
            text-align: center;
            width: 100%; }

        .signInButton {
            width: 100%;
            padding: 10px 0;
            margin-bottom: 10px;
            color: white;
            border: 2px solid white;
            transition: all 100ms ease;
            cursor: pointer;
            background: #61CE70;
            text-align: center;
            border-radius: 5px;

            &:hover {
                border: 2px solid #61CE70;
                color: #61CE70;
                background: white; } }


        .signUpMsg {
            font-size: 10px;
            text-align: start;
            width: 100%;
            a {
                font-size: 10px;
                color: #2b3852;
                &:hover {
                    color: #61CE70; } } } } }




.Planning {
    background: #CFDFFF; }
.Design {
    background: #D0F0FD; }
.Prototype {
    background: #C2F5E9; }
.Testing {
    background: #D1F7C4; }
.Documenting {
    background: #FFDA83; }
.Launch {
    background: #FEE2D5; }
.MuiInputLabel-asterisk, .MuiFormLabel-asterisk {
    color: #E9523E; }
.error {
    background: #E9523E; }


.errorInputContainer {
    display: flex;
    align-items: center;
    color: #E9523E;
    font-size: 12px;
    margin-top: 10px;
    svg {
        font-size: 20px;
        margin-right: 5px; } }
.successNameContainer {
    display: flex;
    align-items: center;
    color: #61CE70;
    font-size: 12px;
    margin-top: 10px;
    svg {
        font-size: 20px;
        margin-right: 5px; } }
.projectNameLoadingContainer {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 10px; }


// .MuiMenu-paper
//     min-width: 250px !important
//     left: 52.6% !important
//     top: 248px !important
//     ul
//         padding: 0

